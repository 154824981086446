const Explicaciones = {
    performance: {
        situacion_1: {
            '1': "Decidiste armar la mesa por tu cuenta. Frecuentemente se asume que el hombre posee mayores capacidades que la mujer en situaciones concretas cuando lo cierto es que en muchas de estas eso no es cierto y simplemente se subestiman sus competencias.  Al armar la mesa por tu cuenta te encuentras subestimando la capacidad de la mujer al creer que no es capaz de realizar la tarea por sí misma y además limitas su accionar.",
            '2': "Decidiste ayudarle a armar la mesa. Frecuentemente se asume que el hombre posee mayores capacidades que la mujer en situaciones concretas cuando lo cierto es que en muchas de estas eso no es cierto y simplemente se subestiman sus competencias. Al ayudarle a armar la mesa sin que haya solicitado previamente tu ayuda te encuentras inconscientemente asumiendo que la mujer no es capaz de realizar la tarea por sí sola. Si bien lo correcto parece ayudarla se debe primero preguntar si requiere de ayuda y no asumir que la necesita.",
            '3': "Decidiste pasarle los guantes para que no se dañe los dedos. Frecuentemente se asume que el hombre posee mayores capacidades que la mujer en situaciones concretas cuando lo cierto es que en muchas de estas eso no es cierto y simplemente se subestiman sus competencias. Al pasarle los guantes confías y crees que la mujer es completamente capaz de realizar la tarea por su cuenta y que no requiere de ayuda.",
            '4': "Decidiste explicarle cómo armar la mesa. Frecuentemente se asume que el hombre posee mayores capacidades que la mujer en situaciones concretas cuando lo cierto es que en muchas de estas eso no es cierto y simplemente se subestiman sus competencias. Al explicarle cómo armar la mesa sin que haya solicitado previamente tu ayuda te encuentras inconscientemente asumiendo que la mujer no es capaz de realizar la tarea por sí sola. Si bien lo correcto parece ayudarla se debe primero preguntar si requiere de ayuda y no asumir que la necesita.",

        },
        situacion_2: {
            '1': "Decidiste recomendarle escoger otra carrera. Resulta muchas veces común asociar profesiones al género de las personas, creando estereotipos y asumiendo que los hombres tienen mayores capacidades para realizar ciertos trabajos, especialmente aquellos relacionados con las ciencias e ingenierías. Una mujer es tan capaz como un hombre en estas áreas. Al recomendarle otra carrera a tu hermana, te encuentras indirectamente apoyando estos estereotipos.  ",
            '2': "Decidiste apoyarla en su decisión. Resulta muchas veces común asociar profesiones al género de las personas, creando estereotipos y asumiendo que los hombres tienen mayores capacidades para realizar ciertos trabajos, especialmente aquellos relacionados con las ciencias e ingenierías. Una mujer es tan capaz como un hombre en estas áreas. Al apoyar la decisión de tu hermana, te encuentras combatiendo estos estereotipos.  ",
            '3': "Decidiste criticar su decisión. Resulta muchas veces común asociar profesiones al género de las personas, creando estereotipos y asumiendo que los hombres tienen mayores capacidades para realizar ciertos trabajos, especialmente aquellos relacionados con las ciencias e ingenierías. Una mujer es tan capaz como un hombre en estas áreas. Al recomendarle otra carrera a tu hermana, te encuentras apoyando estos estereotipos.  ",
        },
        situacion_3: {
            '1': "Decidiste preguntar por su jefe. Las personas tienden a pensar en ocasiones que las mujeres son menos competentes que los hombres lo que los conduce a tomarlas con menor seriedad y asumir que tienen un menor poder y estatus. Al preguntar por su jefe te encuentras apoyando esta misma idea y asumes que la mujer no tiene la capacidad de liderar la empresa.",
            '2': "Decidiste pedir un café. Las personas tienden a pensar en ocasiones que las mujeres son menos competentes que los hombres lo que los conduce a tomarlas con menor seriedad y asumir que tienen un menor poder y estatus. Al pedir un café te encuentras realizando esto mismo, rebajas el estatus y poder de la persona por el hecho de ser mujer y asumes que no tiene la capacidad de liderar la empresa. ",
            '3': "Decidiste no hacer nada. Las personas tienden a pensar en ocasiones que las mujeres son menos competentes que los hombres lo que los conduce a tomarlas con menor seriedad y asumir que tienen un menor poder y estatus. Al no hacer nada no asumes nada con respecto a la mujer y no se genera ningún tipo de consecuencia evitando cualquier conflicto. En ocasiones no hacer nada puede ser la mejor opción.",
        },
    },
    maternal: {
        situacion_1: {
            '1': "Escogiste no hacer nada. Tratar de poco profesional a la mujer puede herir su reputación y este tipo de interrupciones no tienen relación alguna con su desempeño, esta es una situación que le puede ocurrir a cualquiera y se encuentra fuera del control de uno mismo. Al no hacer nada, estás apoyando estos comportamientos.",
            '2': "Escogiste mencionarle a tu jefe que esto le puede ocurrir a cualquiera. Tratar de poco profesional a la mujer puede herir su reputación y este tipo de interrupciones no tienen relación alguna con su desempeño, esta es una situación que le puede ocurrir a cualquiera y se encuentra fuera del control de uno mismo. Al explicarle esto mismo a tu jefe, puedes lograr hacerlo recapacitar que no repita estas conductas en el futuro.",
            '3': "Escogiste estar de acuerdo con lo que dice tu jefe. Tratar de poco profesional a la mujer puede herir su reputación y este tipo de interrupciones no tienen relación alguna con su desempeño, esta es una situación que le puede ocurrir a cualquiera y se encuentra fuera del control de uno mismo. Al estar de acuerdo, estás apoyando este comportamiento.",
        },
        situacion_2: {
            '1': "Escogiste estar de acuerdo. Denegar las oportunidades de la mujer incluso si son con buenas intenciones fomenta a que no puedan progresar en el trabajo. Tener un hijo no es impedimento para desempeñar las labores correctamente, las mujeres son capaces de distribuir sus tiempos y carga de trabajo al igual que los hombres. Al estar de acuerdo, te encuentras denegando las oportunidades de la mujer.",
            '2': "Escogiste comentarle que este proyecto puede cambiar su carrera. Denegar las oportunidades de la mujer incluso si son con buenas intenciones fomenta a que no puedan progresar en el trabajo. Tener un hijo no es impedimento para desempeñar las labores correctamente, las mujeres son capaces de distribuir sus tiempos y carga de trabajo al igual que los hombres. Al escoger esta opción, confías en que la mujer es capaz de llevar adelante el proyecto.",
            '3': "Escogiste comentarle que el bebé afectará en su rendimiento, pero debería aceptar la oportunidad. Denegar las oportunidades de la mujer incluso si son con buenas intenciones fomenta a que no puedan progresar en el trabajo. Tener un hijo no es impedimento para desempeñar las labores correctamente, las mujeres son capaces de distribuir sus tiempos y carga de trabajo al igual que los hombres. Al escoger esta opción, no crees que la mujer sea capaz de distribuir bien sus tiempos, pero aún así le quieres dar la oportunidad.",
            '4': "Escogiste comentarle que tener un bebé no es impedimento para trabajar. Denegar las oportunidades de la mujer incluso si son con buenas intenciones fomenta a que no puedan progresar en el trabajo. Tener un hijo no es impedimento para desempeñar las labores correctamente, las mujeres son capaces de distribuir sus tiempos y carga de trabajo al igual que los hombres. Al escoger esta opción, le das a entender a tu compañero esto mismo."
        },
        situacion_3: {
            '1': "Escogiste no comentar nada. Cuando las mujeres con hijos son excluidas de las actividades puedes detener el crecimiento en su carrera profesional así como también se genera un sentimiento de exclusión. Esto puede traer varios problemas, especialmente en empresas que desean retener a las mujeres trabajadoras. Al no hacer nada, te encuentras fomentando indirectamente estos comportamientos",
            '2': "Escogiste comentar que debió preguntarle antes de asumir que no vendría. Cuando las mujeres con hijos son excluidas de las actividades puedes detener el crecimiento en su carrera profesional así como también se genera un sentimiento de exclusión. Esto puede traer varios problemas, especialmente en empresas que desean retener a las mujeres trabajadoras. Al escoger esta opción, estás aportando a integrar a la mujer en el grupo y combatiendo estas actitudes.",
            '3': "Escogiste comentar que hizo bien en no asignarle más carga de trabajo. Cuando las mujeres con hijos son excluidas de las actividades puedes detener el crecimiento en su carrera profesional así como también se genera un sentimiento de exclusión. Esto puede traer varios problemas, especialmente en empresas que desean retener a las mujeres trabajadoras. Al no hacer nada, te encuentras fomentando estos comportamientos.",
        },
    },
    unconscious: {
        situacion_1: {
            '1': "Escogiste no hacer nada. Es común en nuestro día a día asociar inconscientemente conceptos al género de las personas. Comentarios como 'ese trago es de mina' o 'escoge algo más de hombre' fortalecen esta idea y lamentablemente generan esquemas de cómo una mujer o un hombre debe comportarse cuando son ellos mismos quienes deben decidir como hacerlo. Al no hacer nada, aportas indirectamente a que esto ocurra.",
            '2': "Escogiste preguntar qué es un 'trago de mina'. Es común en nuestro día a día asociar inconscientemente conceptos al género de las personas. Comentarios como 'ese trago es de mina' o 'escoge algo más de hombre' fortalecen esta idea y lamentablemente generan esquemas de cómo una mujer o un hombre debe comportarse cuando son ellos mismos quienes deben decidir como hacerlo. Al preguntar esto, permites que las personas cuesitonen su comentario, y generen conciencia de lo dicho.",
            '3': "Escogiste molestarlo también. Es común en nuestro día a día asociar inconscientemente conceptos al género de las personas. Comentarios como 'ese trago es de mina' o 'escoge algo más de hombre' fortalecen esta idea y lamentablemente generan esquemas de cómo una mujer o un hombre debe comportarse cuando son ellos mismos quienes deben decidir como hacerlo. Al decidir hacer esto, aportas a que esto ocurra.",
            '4': "Escogiste ofrecerle un trago 'más de hombre'. Es común en nuestro día a día asociar inconscientemente conceptos al género de las personas. Comentarios como 'ese trago es de mina' o 'escoge algo más de hombre' fortalecen esta idea y lamentablemente generan esquemas de cómo una mujer o un hombre debe comportarse cuando son ellos mismos quienes deben decidir como hacerlo. Al decidir hacer esto, aportas a que esto ocurra."
        },
        situacion_2: {
            '1': "Escogiste no hacer nada. En ocasiones los niños desarrollan y forjan su personalidad en base a la de sus padres. Es aqui en donde se debe ser extremadamente cuidadoso y evitar que repliquen los mismos sesgos que los padres poseen, especialmente los sesgos inconscientes. Se debe apoyar al niño en la personalidad que el decida tener y no la que el padre o madre decida. Al escoger esta opción, estás apoyando indirectamente esta conducta.",
            '2': "Escogiste decirle que su hija puede decidir el juguete que quiera. En ocasiones los niños desarrollan y forjan su personalidad en base a la de sus padres. Es aqui en donde se debe ser extremadamente cuidadoso y evitar que repliquen los mismos sesgos que los padres poseen, especialmente los sesgos inconscientes. Se debe apoyar al niño en la personalidad que el decida tener y no la que el padre o madre decida. Al escoger esta decisión, le haces entender a tu compañero esto mismo para que genere consciencia de ello.",
            '3': "Escogiste comentarle que cada vez se está perdiendo más la identidad de género. En ocasiones los niños desarrollan y forjan su personalidad en base a la de sus padres. Es aqui en donde se debe ser extremadamente cuidadoso y evitar que repliquen los mismos sesgos que los padres poseen, especialmente los sesgos inconscientes. Se debe apoyar al niño en la personalidad que el decida tener y no la que el padre o madre decida. Al escoger esta decisión, estás aportando a este comportamiento.",
        },
        situacion_3: {
            '1': "Escogiste ir a buscar una silla. Gestos insconscientes como ceder el asiento a una mujer o abrirle la puerta para que pase primero atentan contra la igualdad de género. No debe confundirse la cortesía con el machismo, una mujer es completamente capaz de hacer estas tareas por su cuenta y en una sociedad en donde se busca la igualdad, no se debe hacer distinción entre un hombre y una mujer en este tipo de situaciones. Al ir a buscar una silla, te encuentras haciendo una distinción entre un hombre y una mujer.",
            '2': "Escogiste no hacer nada. Gestos insconscientes como ceder el asiento a una mujer o abrirle la puerta para que pase primero atentan contra la igualdad de género. No debe confundirse la cortesía con el machismo, una mujer es completamente capaz de hacer estas tareas por su cuenta y en una sociedad en donde se busca la igualdad, no se debe hacer distinción entre un hombre y una mujer en este tipo de situaciones. Al no hacer nada, efectivamente no te encuentras realizando este tipo de distinsiones.",
        },
        situacion_4: {
            '1': "Escogiste no hacer nada. Un estudio realizado reveló que el 66% de las mujeres reciben comentarios negativos por su forma de vestir mientras que en el caso de los hombres solo el 1% recibió un comentario de este estilo. De manera inconsciente se espera que una mujer vista y actúe de cierta forma cuando lo cierto es que son libres de vestir y comportarse como lo deseen sin cumplir con los estereotipos que la sociedad impone. Al no hacer nada, estás apoyando indirectamente estos comportamientos.",
            '2': "Escogiste comentar que no tiene nada de raro como está vestida. Un estudio realizado reveló que el 66% de las mujeres reciben comentarios negativos por su forma de vestir mientras que en el caso de los hombres solo el 1% recibió un comentario de este estilo. De manera inconsciente se espera que una mujer vista y actúe de cierta forma cuando lo cierto es que son libres de vestir y comportarse como lo deseen sin cumplir con los estereotipos que la sociedad impone. Al tomar esta decisión, rompes con los esquemas de cómo una mujer debe vestir.",
            '3': "Escogiste comentar que también crees que se vistió de forma anormal. Un estudio realizado reveló que el 66% de las mujeres reciben comentarios negativos por su forma de vestir mientras que en el caso de los hombres solo el 1% recibió un comentario de este estilo. De manera inconsciente se espera que una mujer vista y actúe de cierta forma cuando lo cierto es que son libres de vestir y comportarse como lo deseen sin cumplir con los estereotipos que la sociedad impone. Al tomar esta decisión, aportas a continuar con estos estereotipos.",
        },
    },
    attribution: {
        situacion_1: {
            '1': "Decidiste no hacer nada. Las mujeres ganan en promedio un 27% menos que un hombre por hacer el mismo trabajo. Al decidir no hacer nada sabiendo que ambos hicieron el mismo trabajo, estás fomentando esto.",
            '2-1': "Decidiste ir a hablar con tu jefe. Las mujeres ganan en promedio un 27% menos que un hombre por hacer el mismo trabajo. Al decidir hablar con tu jefe, estás ayudando a combatir esta cifra para lograr una mayor igualdad.# Decidiste no estar de acuerdo con tu jefe. Al no estar de acuerdo con entregar parte de tu bono a tu compañera, si bien te preocupaste porque ambos ganen lo mismo, al momento de actuar no ayudaste en lo absoluto.",
            '2-2': "Decidiste ir a hablar con tu jefe. Las mujeres ganan en promedio un 27% menos que un hombre por hacer el mismo trabajo. Al decidir hablar con tu jefe, estás ayudando a combatir esta cifra para lograr una mayor igualdad.# Decidiste estar de acuerdo con tu jefe. Al estar de acuerdo con entregar parte de tu bono a tu compañera, realmente aportaste en la disminución del sesgo pues le entregaste el mérito correspondiente por el trabajo realizado.", 
        },
        situacion_2: {
            '1': "Decidiste seguir hablando de tu partido. Las mujeres frecuentemente suelen tener menos crédito por sus éxitos y más críticas por sus fracasos. Al ignorar a tu hermana indirectamente estás apoyando esta conducta.",
            '2-1': "Decidiste preguntarle a tu padre por qué no se veía tan contento por la victoria de tu hermana. Las mujeres frecuentemente suelen tener menos crédito por sus éxitos y más críticas por sus fracasos. Una buena forma de combatir esto es preguntándole a la persona directamente para que analice su decisión.# Decidiste estar completamente de acuerdo con su afirmación. Al estar de acuerdo con la afirmación de tu padre, le estás quitando mérito a tu hermana pues ganar un torneo requiere de un gran esfuerzo del equipo. ",
            '2-2': "Decidiste preguntarle a tu padre por qué no se veía tan contento por la victoria de tu hermana. Las mujeres frecuentemente suelen tener menos crédito por sus éxitos y más críticas por sus fracasos. Una buena forma de combatir esto es preguntándole a la persona directamente para que analice su decisión.# Decidiste estar de acuerdo con su afirmación pero hacerle entender que aun así ganar un torneo tiene gran mérito. Al tomar esta decisión, de manera general le quitas mérito a las mujeres pero aún así comprendes que ganar un torneo de fútbol femenino requiere de un gran esfuerzo.",
            '2-3': "Decidiste preguntarle a tu padre por qué no se veía tan contento por la victoria de tu hermana. Las mujeres frecuentemente suelen tener menos crédito por sus éxitos y más críticas por sus fracasos. Una buena forma de combatir esto es preguntándole a la persona directamente para que analice su decisión.# Decidiste estar en desacuerdo con su afirmación. Al estar en desacuerdo intentas convencer a tu padre que ganar un torneo independientemente del género tiene un gran mérito, y gracias a esto estás ayudando a combatir el sesgo.",
        },
        situacion_3: {
            '1': "Decidiste felicitar a ambos por igual. Al felicitarlos por igual, consideras que independientemente del género cocinar un buen plato tiene un gran mérito. Aún así, evitaste un conflicto con tu compañero que opina de forma distinta, provocando que siga actuando de forma sesgada.",
            '2': "Decidiste preguntar a tu amigo por qué no felicitó a la mujer por igual. Al preguntarle esto a tu amigo, estás generando que analice y comprenda que la mujer también merece mérito por su trabajo.",
            '3': "Decidiste seguir comiendo. Vivenciaste una situación de sesgo y no decidiste hacer nada al respecto, lo que indirectamente implica que estás fomentando dichas situaciones.",
        },
    },
 
};

export default Explicaciones